// import { AppBar } from '@material-ui/core';

import { Autocomplete } from "@react-google-maps/api";
import logoImage from "./../../assets/hoopers_club_logo.svg";
import "./_search.scss";

const Search = ({ onPlaceChanged, onLoad, styleclass }) => {
	return (
		<div
			className="topbar"
			style={{
				position: "absolute",
				width: "100%",
				zIndex: 1,
				display: "flex",
				flexDirection: "row",
				backgroundColor: "white",
				justifyContent: "space-between",
				alignContent: "center",
				padding: "0.5em",
			}}
		>
			<a href={"https://hoopers.club"} style={{ display: "flex" }}>
				<img
					alt={""}
					src={logoImage}
					style={{
						width: "6em",
						display: "flex",
						padding: "0.25em;",
					}}
				/>
			</a>
			<Autocomplete
				onLoad={onLoad}
				onPlaceChanged={onPlaceChanged}
				autocomplete="on"
				onPlaceSelected={(place) => {
					console.log(place, "printed place");
				}}
			>
				<input
					className={styleclass}
					type="text"
					name="search"
					id="autocomplete"
					placeholder="SEARCH LOCATION"
					autocomplete="on"
				/>
			</Autocomplete>
		</div>
	);
};

export default Search;
