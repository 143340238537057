import "./_courtdetail.scss";
import Markdown from "react-markdown";
import {useHistory} from "react-router-dom";

const CourtDetail = ({court, setCloseBtn}) => {
    const history = useHistory();
    const description = court?.description
        ? court?.description
            .replace(
                new RegExp("!\\[\\]\\(\\/assets\\/uploads\\/", "g"),
                "![](https://hoopers.club/assets/uploads/"
            )
            .replace(
                new RegExp("!\\[\\]\\(\\/", "g"),
                "![](https://map.hoopers.club/"
            )
            .replace(new RegExp("!\\[\\]\\(\\)", "g"), "")
        : "No content available";

    function goToHome() {
        history.push("/");
    }

    return (
        <div className="court-container">
            <div className="court-detail">
                <div
                    className="left"
                    style={{
                        backgroundImage: `url(${court?.cover_img_url})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                >
                    <img
                        className="close-btn"
                        src="/assets/courts/Fechar_Card.svg"
                        alt=""
                        onClick={() => {
                            setCloseBtn(false);
                            goToHome();
                        }}
                    />
                    <div className="icons-parent">
                        <div className="left-icons">
                            {court?.whatsapp_url ? (
                                <div className="left-icons-div">
                                    <a
                                        href={court?.whatsapp_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            className="link-icons-img"
                                            src="/assets/courts/join-groups_icon.svg"
                                            alt=""
                                        />{" "}
                                        <div className="icon-text-div">
                                            JOIN GROUP
                                        </div>
                                    </a>
                                </div>
                            ) : null}
                            {court?.calendly_url ? (
                                <div className="left-icons-div">
                                    <a
                                        href={court?.calendly_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            className="link-icons-img"
                                            src="/assets/courts/schedule_icon.svg"
                                            alt=""
                                        />
                                        <div className="icon-text-div">
                                            SCHEDULE GAMES
                                        </div>
                                    </a>
                                </div>
                            ) : null}
                            {court?.teamup_url ? (
                                <div className="left-icons-div">
                                    <a
                                        href={court?.teamup_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            className="link-icons-img"
                                            src="/assets/courts/join-games_icon.svg"
                                            alt=""
                                        />
                                        <div className="icon-text-div">
                                            JOIN GAMES
                                        </div>
                                    </a>
                                </div>
                            ) : null}
                            <div className="left-icons-div">
                                <a
                                    href={`https://www.google.com/maps/dir/?api=1&destination=${court?.lat},${court?.lng}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="link-icons-img"
                                        src="/assets/courts/go-to-court_icon.svg"
                                        alt=""
                                    />
                                    <div className="icon-text-div">
                                        GO TO COURT
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <img src="/assets/courts/courtseparatordetail.svg" alt=""/>
                    {(court.is_hoopers === false && court.creator_social) ? (
                        <div className="by-hoopers">
                            <div>court by {court?.creator_social} </div>
                        </div>
                    ) : (
                        <div className="by-hoopers">court by hoopers</div>
                    )}
                    <div className="court-detail-info-title">{court?.name}</div>
                    <div className="court-detail-double">
                        <div className="court-detail-info detail-align-center">
                            <div className="title">Location</div>
                            <div className="detail detail-location">
                                <div className="detail-location-text">
                                    {court?.address}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="court-detail-double">
                        <div className="court-detail-info detail-align-center">
                            <div className="title">Game Time:</div>
                            <div className="detail detail-location">
                                <div className="detail-location-text">
                                    {court?.open_time}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="court-detail-double">
                        <div className="court-detail-info detail-align-center">
                            <div className="title">Cost:</div>
                            <div className="detail detail-location">
                                <div className="detail-location-text">
                                    {court?.is_free === true
                                        ? "Free"
                                        : "Not Free"}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** <div className="court-detail-info">
                     <div className="title">Join this Court's Whatsapp Group</div>
                     <div className="detail">
                     <a href={court?.whaLink} target="_blank" rel="noreferrer">
                     AQUI PINO
                     </a>
                     </div>
                     </div>*/}
                    <div className="court-detail-double">
                        <div className="court-detail-info detail-align-center">
                            <div className="title">About:</div>
                            <div className="detail history">
                                <Markdown children={description} components={{
                                    img: ({node, ...props}) => (
                                        <span/>
                                    )
                                }}/>
                            </div>
                        </div>
                    </div>

                    <img src="/assets/courts/courtseparatordetail.svg" alt=""/>
                </div>
            </div>
        </div>
    );
};

export default CourtDetail;
