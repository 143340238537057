import "./_buttons.scss";

const LinkedButtons = () => {
	return (
		<div className="buttons centered uplifted">
			<a
				href="https://hoopers.club/download-app"
				target="_blank"
				rel="noreferrer"
			>
				Add Court
			</a>
		</div>
	);
};

export default LinkedButtons;
